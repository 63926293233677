<template lang="pug">
div
  span
  v-menu(
    offset-y
    right
    :close-on-content-click="false"
    v-model="menu"
    min-width="300"
    max-width="480"
  ).text-center
    template(v-slot:activator="{ on }")
      slot(name="activator" :on="on")
        v-btn(v-on="on" text :disabled="disabled").btn-common.mr-2 {{ btnText }}

    .labels
      .labels__content(data-test="c-labels-popup")
        search(v-model="search" placeholder="Enter label name...").mb-2
        div(v-for="item in filteredItems" :key="item[itemValue]")
          app-label(:label="item[itemText]" :color="item.color" :keyValue="item[itemValue]" :value="labels" v-model="labels" :class="[item.active, 'label_same-size']").mb-1
            <template v-slot:additional>
              slot(name="item-additional" :item="item")
            </template>
        slot

      v-divider
      .d-flex.pa-3
        slot(name="actions" :on="toggle")
        v-spacer
        v-btn(text color="primary" small @click="cancel") cancel
        v-btn(color="warning" v-if="!hideExclude" small @click="submitExclude" :disabled="!newItemsSelected").mr-2 Exclude
        v-btn(color="primary" small @click="submit" :disabled="!newItemsSelected" data-test="label-create-submit") Apply
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
      default: () => []
    },
    value: {
      required: true
    },
    itemValue: String,
    itemText: {
      type: String,
      default: 'label'
    },
    btnText: {
      type: String,
      default: 'labels'
    },
    hideExclude: Boolean,
    disabled: Boolean
  },

  data: () => ({
    menu: false,
    search: null,
    labels: []
  }),

  computed: {
    newItemsSelected() {
      return true
    },

    filteredItems() {
      if (!this.search || this.search.length < 2) return this.items
      return this.items.filter(item => {
        if (item[this.itemText].toLowerCase().includes(this.search.toLowerCase()))
          return item
      })
    }
  },

  mounted() {
    this.setLabels()
  },

  methods: {
    setLabels() {
      this.labels = [...this.value]
    },

    resetSearch() {
      this.search = null
    },

    submit() {
      this.$emit('changed', this.labels)
      this.menu = false
    },

    submitExclude() {
      this.$emit('changed:exclude', this.labels)
      this.menu = false
    },

    cancel() {
      this.setLabels()
      this.menu = false
    },

    toggle() {
      this.menu = !this.menu
    }
  },

  watch: {
    value() {
      this.setLabels()
      this.resetSearch()
    }
  },

  components: {
    appLabel: () => import('@/components/global/Label.vue'),
    search: () => import('@/components/global/Search.vue')
  }
}
</script>

<style lang="scss" scoped>
.labels {
  background-color: #fff;
  max-height: 55vh;
  overflow: auto;

  &__content {
    padding: 10px;
  }
  &__item {
    width: calc(33% + 0px);
    margin: 0 -5px;
  }
}
</style>
